<template>
    <div>
        <div class="form-row form-row_grid">
            <div class="form-col">
                <label for="headlineOne">headline</label>
                <text-input id="headlineOne" v-model="block.headlineOne" autocomplete="off" />
                <span v-if="hasError('block.headlineOne')" class="form-error">{{ showError('block.headlineOne') }}</span>

                <label for="headlineTwo" class="mt-8">sub-headline</label>
                <text-input id="headlineTwo" v-model="block.headlineTwo" autocomplete="off" />
                <span v-if="hasError('block.headlineTwo')" class="form-error">{{ showError('block.headlineTwo') }}</span>

                <label class="mt-8">image</label>
                <file-manager
                    :main-directory="'cms'"
                    :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                    :libraryFilters="['marketing', 'cms']"
                    :max-nb-of-files="nbMaxImages"
                    :max-upload-size="20"
                    :cropper-options="{aspectRatio: 1, cropBoxResizable: true, zoomOnWheel: false}"
                    :noCropper="true"
                    @uploaded-to-s3="createWebsiteImage"
                    v-model="block.images"
                ></file-manager>
                <span v-if="hasError('block.images')" class="form-error">{{ showError('block.images') }}</span>
            </div>
            <div class="form-col">
                <label for="content">content</label>
                <vue-editor v-model="block.content" id="content" :editorToolbar="customToolbar"></vue-editor>
                <span v-if="hasError('block.content')" class="form-error">{{ showError('block.content') }}</span>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";
    import { VueEditor } from "vue2-editor";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import { toolbarConfig } from "@/utils/RichTextEditor";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import FileManager from "@/components/ui/filemanager/FileManager";
    import TextInput from '@/components/ui/TextInput';

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: { ModalFooter,  FileManager, VueEditor, TextInput },
        emits: ['save-page-widget'],
        data: () => {
            return {
                block: {
                    headlineOne: null,
                    headlineTwo: null,
                    content: null,
                    images: []
                },
                nbMaxImages: 3,
                customToolbar: toolbarConfig,
                isProcessing: false
            }
        },
        computed: {
            ...mapGetters({
                getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
                getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl'
            })
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    let images = [];
                    for( let i = 0; i < this.block.images.length; i++){
                        images[i] = (this.getThumbnailUrlByFileId(this.block.images[i]));
                    }
                    this.$emit('save-page-widget', {
                        headlineOne: this.block.headlineOne,
                        headlineTwo: this.block.headlineTwo,
                        content: this.block.content,
                        images: JSON.stringify(images),
                    });
                }
            },
        },
        validations: {
            'block.headlineOne' : 'required',
            'block.headlineTwo' : 'required',
            'block.content': 'required',
            'block.images': 'required | max:nbMaxImages'
        },
        created() {
            this.initValidator();

            if (this.values.length) {
                let images=[]
                for( let i = 0; i < JSON.parse(this.getValue('images')).length; i++){
                    images[i] = (this.getFileIdByThumbnailUrl(JSON.parse(this.getValue('images'))[i]));
                }
                Object.assign(this.block, {
                    headlineOne: this.getValue('headlineOne'),
                    headlineTwo: this.getValue('headlineTwo'),
                    content: this.getValue('content'),
                    images: JSON.stringify(images) ? images : []
                });
            }
        }
    }
</script>
